import { MyInput } from "./input";
import "./body.css";
import { MyText } from "./text";
import { MyInputNumber } from "./inputNumber";
import { Colors } from "../colors";
import { ParseBTN } from "./parse-btn";

// export function Body() {
//   return (
// <div
//   className="mainDiv"
//   style={{ backgroundColor: `#${Colors.bgColor}FF` }}
// >
//   <MyText text="Filters" />
//   <MyInput id="filters" />
//   <MyText text="Count" />
//   <MyInputNumber id="count" />
//   <ParseBTN onClick={() => {}} disabledBTN={false} />
// </div>
//   );
// }

import React from "react";
import { MyLink } from "./link";

interface IState {
  filter: string;
  count: string;
  buttonDisabled: boolean;
  href: string;
  status: string;
  page: string;
}
export class Body extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      filter: "",
      count: "1",
      page: "1",
      buttonDisabled: true,
      status: "",
      href: "",
    };
    setInterval(() => {
      // fetch("https://" + window.location.host + "/status")
      fetch("https://mungi.eu-4.evennode.com/status", {})
        .then((r) => r.text())
        .then((r) => {
          console.log(r);
          if (r.includes("xlsx")) {
            this.setState({
              href:
                "https://mungi.eu-4.evennode.com/downloadLastTable?path=" + r,
              status: r,
            });
          } else {
            this.setState({
              href: "",
              status: r,
            });
          }
        });
    }, 300);
  }
  render(): React.ReactNode {
    return (
      <div
        className="mainDiv"
        style={{ backgroundColor: `#${Colors.bgColor}FF` }}
      >
        <MyText text="Filters" />
        <MyInput
          id="filters"
          onChange={(e) => {
            console.log(
              !(
                this.state.count !== "" &&
                parseInt(this.state.count) > 0 &&
                this.state.filter !== ""
              )
            );
            this.setState({ filter: e.currentTarget.value });
          }}
        />
        <MyText text="Count" />
        <MyInputNumber
          id="count"
          onChange={(v) => {
            this.setState({ count: v.toString() });
          }}
        />
        <MyText text="Page" />
        <MyInputNumber
          id="start-page"
          onChange={(v) => {
            this.setState({ page: v.toString() });
          }}
        />
        <ParseBTN
          onClick={() => {
            // console.log(this.state.count);
            fetch(
              "https://mungi.eu-4.evennode.com/parse?filter=" +
                this.state.filter +
                "&count=" +
                this.state.count +
                "&page=" +
                this.state.page
            );
          }}
          disabledBTN={
            !(
              this.state.count !== "" &&
              parseInt(this.state.count) > 0 &&
              this.state.filter !== ""
            )
          }
        />
        <MyLink text={this.state.status} href={this.state.href} />
      </div>
    );
  }
}
