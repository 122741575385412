import { InputProps } from "./@types";
import { Colors } from "../colors";
import { StrictMode } from "react";

export function MyInput({ id,onChange }: InputProps,) {
  return (
    <StrictMode>
      <div>
        <input
          id={id}
          className="defaultInput"
          // border-color={Colors.primaryColor}
          onChange={onChange}
          style={{
            backgroundColor: `#${Colors.accent}`,
            width: "400px",
            fontWeight: "bold",
            fontSize: 18,
            borderColor: `#${Colors.primaryColor}FF`,
          }}
          //  = {Colors.accent}
        ></input>
      </div>
    </StrictMode>
  );
}
