import React from "react";
import minus from "../minus.svg";

interface IProps {
  onClick: () => void;
}

interface IState {
  func: () => void;
}

export class MinusBTN extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { func: props.onClick };
  }
  render(): React.ReactNode {
    return (
      <img
        src={minus}
        height="36px"
        alt=""
        onClick={this.state.func}
        style={{ userSelect: "none" }}
      />
    );
  }
}
