import React from "react";
import { TextProps } from "./@types";
import { Colors } from "../colors";
export function MyText({ text }: TextProps) {
  return (
    <div style={{ fontSize: "30px", padding: "16px", fontWeight: 'bold', color:`#${Colors.primaryColor}`}}>
      {text}
    </div>
  );
}
