import { Colors } from "../colors";
import { LinkProps } from "./@types/link-props.type";
export function MyLink({ text,href }: LinkProps) {
  return (
    <a href={href}>
      <div
        style={{
          fontSize: "30px",
          padding: "8px",
          fontWeight: "bold",
          color: `#${Colors.text}`,
        }}
      >
        {text}
      </div>
    </a>
  );
}
