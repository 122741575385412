import { Colors } from "../colors";
import { StrictMode } from "react";
import { PlusBTN } from "./plus-btn";
import { MinusBTN } from "./minus-btn";

import React from "react";

interface IProps {
  id: string;
  onChange: (n: number) => void;
}

interface IState {
  _id: string;
  value: number;
}

export class MyInputNumber extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { _id: props.id, value: 1 };
  }
  render(): React.ReactNode {
    return (
      <StrictMode>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MinusBTN
            onClick={() => {
              if (this.state.value > 1) {
                this.setState({ value: this.state.value - 1 });
                this.props.onChange(this.state.value-1);
              }
            }}
          ></MinusBTN>
          <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
            <input
              onInput={(e) => {
                if (parseInt(e.currentTarget.value) > 0) {
                  console.log(e.currentTarget.value)
                  this.setState({ value: parseInt(e.currentTarget.value) });
                  this.props.onChange(parseInt(e.currentTarget.value));
                } else {
                  this.setState({ value: 1 });
                  this.props.onChange(1);
                }
              }}
              id={this.props.id}
              className="inputNumber"
              type="number"
              min="1"
              max="1000000000000"
              //   border-color={Colors.primaryColor}
              value={this.state.value}
              style={{
                textAlign: "center",
                backgroundColor: `#${Colors.accent}`,
                width: "300px",
                fontSize: 18,
                fontWeight: "bold",
                borderColor: `#${Colors.primaryColor}FF`,
              }}
              //  = {Colors.accent}
            ></input>
          </div>
          <PlusBTN
            onClick={() => {
              this.setState({ value: this.state.value + 1 });
              this.props.onChange(this.state.value+1);
            }}
          ></PlusBTN>
        </div>
      </StrictMode>
    );
  }
}
