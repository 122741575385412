import React from "react";
import { Colors } from "../colors";

interface IProps {
  onClick: () => void;
  disabledBTN: boolean;
}

interface IState {
  func: () => void;
  disabled: boolean;
  buttonOpacityEnabled: string;
  buttonOpacityDisabled: string;
}

export class ParseBTN extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      func: props.onClick,
      disabled: props.disabledBTN,
      buttonOpacityEnabled: "FF",
      buttonOpacityDisabled: "99",
    };
  }
  render(): React.ReactNode {
    return (
      <div style={{ padding: "20px" }}>
        <button
          onClick={() => {
            this.state.func();
          }}
          disabled={this.props.disabledBTN}
          style={{
            height: "40px",
            width: "416px",
            borderRadius: "14px",
            borderWidth: "0px",
            backgroundColor: `#${Colors.primaryColor}${
              this.props.disabledBTN
                ? this.state.buttonOpacityDisabled
                : this.state.buttonOpacityEnabled
            }`,
            borderStyle: "solid",
            fontSize: 18,
            fontWeight: "bold",
            color: `#${Colors.bgColor}${
              this.props.disabledBTN
                ? this.state.buttonOpacityDisabled
                : this.state.buttonOpacityEnabled
            }`,
            userSelect: "none",
            // borderColor: `#${Colors.primaryColor}${this.state.buttonOpacity}`,
          }}
        >
          Parse
        </button>
      </div>
    );
  }
}
